.header
  position: fixed
  top: 0
  left: 0
  right: 0
  padding:
    top: $gap--m
    bottom: $gap--s
  display: flex
  justify-content: center
  align-items: center
  z-index: 90
  transition: $transition--main

  &::before
    content: ""
    display: block
    position: absolute
    top: 0
    height: 100%
    width: 100vw
    backdrop-filter: blur(10px)
    z-index: -1
    transition: $transition--main

  &__main
    margin-right: auto

    &--link > .desktop-view
      display: flex
      height: 2.75rem
      justify-content: center
      align-items: center
      opacity: 0
      transition: opacity $transition--main

      & > img
        height: 100%
        object:
          fit: contain
          position: left


  &__nav
    margin-left: auto

    &--list
      display: grid
      grid-auto-flow: column
      column-gap: $gap--s
      align-items: center
      width: max-content
      color: $color--white

  &__contact
    position: relative
    display: flex
    padding: $gap--xxxs $gap--s
    color: $color--orange
    border: $border--main $color--orange
    background-color: rgba($color--orange, .15)
    transition: $transition--main

    &:hover
      color: $color--white
      background-color: rgba($color--orange, .5)

  &.main

    &::before
      background-color: rgba($color--white, .5)

    .header__main--link > .desktop-view
      opacity: 1

    .text--link--highlight
      color: $color--black

      &::before
        background-color: $color--black

.hero
  box-sizing: border-box
  height: 100vh
  max-height: 70rem
  min-height: fit-content
  color: $color--white
  padding:
    top: $gap--xxl
    bottom: $gap--xxl
