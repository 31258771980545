.text--title--l
  font-size: 5rem
  font-weight: 700
  line-height: 1.1

.text--title--m
  font-size: 3rem
  font-weight: 700
  line-height: 1.25

.text--title--s
  font-size: 1.5rem
  font-weight: 500
