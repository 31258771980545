.home--hero
  display: grid
  align-items: flex-end
  @include bg-img("../img/hero-desktop.webp")

  &__heading
    display: grid
    row-gap: $gap--s
    width: calc(100% - var(--gap--m) * 2)

    &--logo
      width: 10rem
      margin-bottom: $gap--s

    &--subtitle
      max-width: $home-hero-subtitle

.events
  margin-top: $gap--xxl

  &__all
    background-color: $color--orange
    outline: 2px solid transparent
    transition: $transition--main

    &--photo
      position: relative
      overflow: hidden

      & > .events__img
        transform-origin: right bottom
        transform: scale(1.5)
        object-position: right
        &:hover
          transform: scale(1.5)

      & > .label
        position: absolute
        top: 0
        right: 0

    &--info
      grid-template-columns: auto 1fr
      grid-template-areas: "title title" "subtitle subtitle" "description description" "date time" "location location"
      padding:
        left: var(--gap--m)
        top: var(--gap--m)
        bottom: var(--gap--m)

      &__title
        grid-area: title
        text-decoration-line: underline
        text-decoration-color: $color--orange
        transition: text-decoration-color $transition--main

      &__subtitle
        grid-area: subtitle

      &__description
        grid-area: description

      &__date
        grid-area: date

      &__time
        grid-area: time

      &__location
        grid-area: location

    &:hover
      outline-color: $color--black

      .events__all--info__title
        text-decoration-color: $color--black

// Sin eventos
.no-event
  display: grid
  grid-template-columns: 2fr 1fr
  grid-template-rows: 1fr auto
  grid-template-areas: "hgroup image" "link image"
  gap: $gap--m
  background-color: $color--orange
  padding: $gap--m
  position: relative
  margin:
    top: $gap--xxl
    bottom: $gap--xxl
  &::after
    $size: 150px
    display: block
    content: ""
    width: $size
    height: $size
    position: absolute
    top: -70px
    right: 69px
    background:
      image: url("../img/talks/loading.svg")
      size: cover
    animation:
      name: animation-rotation-loading
      duration: 10s
      iteration-count: infinite
      timing-function: linear
  &__hgroup
    grid-area: hgroup
  &__title
    margin-bottom: $gap--m
  &__wrap-image
    grid-area: image
    background-color: $color--white
  &__image
    width: 100%
    height: 100%
    object-fit: cover
    object-position: right bottom
    transform: translatex(1.5rem)
  &__wrap-link
    grid-area: link

// Comunidad
.community
  margin-top: $gap--xxl

// ¿Conoces Python?
.python
  margin-top: $gap--xxl

  &__definition
    display: grid
    row-gap: $gap--s
    max-width: $small-width
    @include center-h

// Ventajas de pertenecer a la comunidad
.pros
  margin-top: $gap--xxl
  background-color: $color--grey
  padding:
    top: $gap--xxl
    bottom: $gap--xxl

  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    column-gap: $gap--s

    & > li
      display: grid
      row-gap: $gap--s

// Preguntas frecuentes
.faqs
  margin-top: $gap--xxl

  &__all

    &--questions
      border: $border--main $color--black

      &__item
        transition: $transition--main

        &:not(&:last-child)
          border-bottom: $border--main $color--black

        & > summary
          display: grid
          grid-template-columns: 1fr auto
          column-gap: $gap--xs
          padding: var(--gap--m)
          transition: $transition--main

          &:hover
            color: $color--orange

            & > .icon-open
              &::before,
              &::after
                background-color: $color--orange

        &[open]
          background-color: $color--grey

          & > summary > .icon-open
            transform: rotate(45deg)

        & > p
          padding:
            bottom: var(--gap--m)
            left: var(--gap--m)
            right: var(--gap--m)
.partners
  display: grid
  margin-top: $gap--xxl
  row-gap: $gap--l

  &__list
    display: flex
    justify-content: center
    align-items: center
    column-gap: $gap--l

  &__link
    display: flex
    align-items: center
    height: 6rem
    width: 100%
    max-width: 17rem
    opacity: .8
    transition: $transition--main

    &:hover
      opacity: 1

      & > .partners__image
        transform: scale(1.1)

  &__image
    width: inherit
    object-fit: contain
    transition: $transition--main

    &--idecrea
      height: 100%
      justify-self: right

    &--wayco 
      height: 75%
      justify-self: left

.code-of-conduct
  margin-top: $gap--xxl
  background-color: $color--grey
  padding-block: $gap--xxl

  div

    & > h2
      margin-bottom: $gap--s

    & > h3
      margin-top: $gap--m

    & > p
      margin-top: $gap--xxs

    & > ul
      margin-block: $gap--s

      & > li
        position: relative
        margin-top: $gap--xxs
        padding-left: $gap--m

        &::before
          content: '\e803'
          font-family: 'pyvlc-icons'
          font-size: .7rem
          position: absolute
          top: .2rem
          left: 0

  &__more-content
    transition: $transition--slow

  &__more-button
    background-color: unset
    border: unset
    padding: unset
    margin-top: $gap--m
    transition: $transition--main

    &::before
      height: 2px
      background-color: $color--black

    &:hover
      color: $color--orange

      &::before
        background-color: $color--orange

// Newsletter
.newsletter
  display: grid
  grid-template-columns: repeat(2, 1fr)
  margin-top: $gap--xxl

  &__content
    display: grid
    row-gap: $gap--xs
    padding: $gap--m
    color: $color--white
    background-color: $color--black

    & > h2
      text-decoration-line: underline
      text-decoration-color: $color--black
      text-decoration-thickness: 1px
      transition: text-decoration-color $transition--main

    &--button
      display: grid
      grid-auto-flow: column
      align-items: center
      column-gap: $gap--xxs
      margin-top: $gap--m
      padding-right: $gap--m
      transition: background-color $transition--main

      & > .icon-right-arrow
        font-size: .7rem
        transform-origin: center
        transition: $transition--main

    &:hover

      & > h2
        text-decoration-color: $color--white

      & > .newsletter__content--button
        background-color: $color--orange

        & > .icon-right-arrow
          transform: translate(.5rem) scale(1.1) rotate(-9deg)

// Contacto
.contact
  display: grid
  background-color: $color--blue
  margin-top: $gap--xxl

  &__title
    display: flex
    width: 100%
    overflow: hidden
    border-bottom: $border--main $color--black

    & > h2
      flex-shrink: 0
      font-weight: 400

  &__social-media
    box-sizing: border-box
    display: grid
    grid-template-columns: repeat(3, 1fr)
    justify-self: center
    width: 100%
    max-width: $breakpoint--m
    border-left: $border--main $color--black

    &--item
      border-right: $border--main $color--black

      &__link
        box-sizing: border-box
        display: grid
        grid-template-columns: 1fr auto 1fr
        column-gap: $gap--xs
        height: 100%
        align-items: center

        & > i,
        & > span
          color: $color--black
          transition: $transition--main

        &:hover > i,
        &:hover > span
          color: $color--white

        & > i:not(.icon-right-arrow)
          justify-self: right

        & > .icon-telegram
          font-size: 1.5rem

        & > .icon-twitter
          font-size: 2rem

        & > .icon-slack
          font-size: 1.75rem

        & > .icon-right-arrow
          width: max-content
          font-size: .75rem
          margin-top: .15rem
          transform-origin: center

        &:hover > .icon-right-arrow
          transform: translate(1rem) scale(1.5) rotate(-15deg)

  &__email
    width: 100%
    border-top: $border--main $color--black
    font-size: 1.75rem

    & > i,
    & > i::before
      display: flex
      justify-content: center

  &__title,
  &__social-media--item__link
    padding:
      top: $gap--m
      bottom: $gap--m

  &__email
    padding:
      top: $gap--l
      bottom: $gap--l
